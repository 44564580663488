import React, { Component } from 'react'
import { Navbar, NavDropdown, Button, Nav, Container, Row, Col, InputGroup, FormControl, Form } from 'react-bootstrap'
import { initFetch, initPatch, checkToken, initSubmit } from './lib/service'
import RichTextEditor from 'react-rte'
import * as axios from 'axios'

import './App.css'
import * as Logo from './Logo2_white.png'
import { notifyUser } from './lib/notification'
import { valPassword } from './lib/login'
import { encode } from './lib/hashid'
import { BASE_URL } from './lib/base-url'

class App extends Component {
  repoId = null

  constructor(props) {
    super(props)

    this.state = {
      loggedIn: false,
      language: 'de',
      data: [],
      pages: null,
      editorState: RichTextEditor.createEmptyValue(),
      selectedPageId: null,
      selectedTypeUid: null,
      selectedImage: null,
      uploadedImageId: null,
      files: []
    }

    this.onInputChange = this.onInputChange.bind(this)
    this.changeLanguage = this.changeLanguage.bind(this)
    this.publish = this.publish.bind(this)
    this.onLogin = this.onLogin.bind(this)
    this.onLogout = this.onLogout.bind(this)
    this.onSelectPage = this.onSelectPage.bind(this)
    this.getTypeArray = this.getTypeArray.bind(this)
    this.getTypeName = this.getTypeName.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSelectFormItem = this.onSelectFormItem.bind(this)
    this.onChangeImg = this.onChangeImg.bind(this)
    this.publishImage = this.publishImage.bind(this)
  }

  toObject(arr) {
    let rv = {};
    for (let i = 0; i < arr.length; ++i)
      rv[i] = arr[i];
    return rv;
  }

  toArray(obj) {
    return Object.keys(obj).map((item) => [item = obj[item]][0])
  }

  async componentDidMount() {
    const cookie = localStorage.getItem('x-access-token')

    if (cookie) {
      const res = await checkToken()

      if (res) {
        this.repoId = encode(res.id)
        this.setState({ loggedIn: true })
        this.fetch()
      } else {
        this.setState({ loggedIn: false })
      }
    }
  }

  changeLanguage(lang) {
    this.setState(() => { })
    this.setState({ language: lang }, this.fetch)
  }

  async publish() {
    const payload = { ...this.state }
    payload.data = null
    payload.loggedIn = null

    initPatch('endpoint/' + this.state.language, payload).then(() => {
      notifyUser('', 'Success!', 'success')
    }, () => notifyUser('', 'En error occured', 'warning'))

  }

  async publishImage(contentUid = null) {
    const uploadConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-access-token': localStorage.getItem('x-access-token')
      }
    }

    const resource = 'static/'
    const formData = new FormData()

    formData.append('upload', this.state.selectedImage[0], String(this.state.selectedImage[0].name))

    const response = await axios.post(`${BASE_URL + resource}${this.repoId}/${contentUid}`, formData, uploadConfig)

    window.location.reload()
  }

  async onLogin() {
    await initFetch('auth', this.state.email, true).then((res) => {
      if (res.data) {
        const valid = valPassword(this.state.password, res.data[0].encrypted_password, res.data[0].salt, res.data[0].uid, res.data[0].id)

        if (valid) {
          this.setState({ loggedIn: true })
          this.repoId = encode(res.data[0].id)
          setTimeout(() => {
            this.fetch()
          }, 300);
        }
      }
    })
  }

  onLogout() {
    localStorage.removeItem('x-access-token')
    this.setState({ loggedIn: false })
  }

  async fetch() {
    await initFetch(`endpoint/${this.repoId}/${this.state.language}`).then((res) => {
      if (res && res.data && res.data.content) {

        const contentItems = {}

        res.data.content.map((item) => contentItems[item.uid] = item.content)

        this.setState({
          ...contentItems,
          pages: this.toArray(res.data.pages),
          types: res.data.types,
          content: res.data.content
        }, () => {
          this.setState({
            selectedPageId: this.state.pages[0].id
          })
        })
      }
    })
  }

  onInputChange(e) {
    let object = {}

    object[e.target.name] = e.target.value

    this.setState({
      ...this.state,
      ...object
    })
  }

  onSelectPage(e) {
    e.preventDefault()
    this.setState(
      {
        selectedPageId: +e.target.id
      }, () => this.forceUpdate()
    )
  }

  onSelectFormItem(e) {
    e.preventDefault()
    const uid = e.currentTarget.getAttribute('name')

    this.setState({
      selectedTypeUid: uid,
      editorState: RichTextEditor.createValueFromString(this.state[uid], 'html')
    })
  }

  onChange(target) {
    const newValue = {}
    newValue[this.state.selectedTypeUid] = target.toString('html')
    this.setState({
      editorState: target,
      ...newValue
    })
  }

  onChangeImg(e) {
    e.preventDefault()
    this.setState({ selectedImage: e.target.files })
  }

  getTypeName() {
    if (!this.state.data || !this.state.selectedPageId) {
      return
    }

    const item = this.state.pages.filter((item) => Number(item.id) === Number(this.state.selectedPageId || -1))

    return item[0].name
  }

  getTypeArray() {
    if (!this.state.pages || !this.state.content || !this.state.selectedPageId) {
      return
    }

    const types = this.state.types.filter((item) => Number(item.page_id) === Number(this.state.selectedPageId))
    const content = types.map((type) => this.state.content.filter((item) => Number(item.content_type_id) === Number(type.id)))

    return content[0]
  }

  render() {
    return (
      <div>
        {this.state.loggedIn ? (
          <div>
            <Navbar bg="dark" expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-naƒv">
                <Nav className="mr-auto">
                  <img src={Logo} className="navbar-logo" />
                  <Nav.Link onClick={() => this.onLogout()}>Sign out <li className="fas fa-sign-out-alt" /></Nav.Link>
                  <NavDropdown title={this.state.language === 'de' ? 'Deutsch 🇩🇪' : 'English 🇬🇧'} id="basic-nav-dropdown" >
                    <NavDropdown.Item onClick={() => this.changeLanguage('de')}><span style={{ color: 'black' }}>Deutsch 🇩🇪</span></NavDropdown.Item>
                    <NavDropdown.Item onClick={() => this.changeLanguage('en')} ><span style={{ color: 'black' }}>English 🇬🇧</span></NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <div className="wrapper">
              <div id="sidebar">
                <nav id="sidebar">
                  <div className="sidebar-header">
                    <h3>Content-Types</h3>
                    {this.state.pages ? this.state.pages.map((item) => <a className="type-item" key={item.id} id={item.id} onClick={this.onSelectPage}>{item.name}</a>) : null}
                  </div>
                  <div className='copyright-footer'>
                    <Button variant="success" onClick={this.publish}>{this.state.language === 'de' ? 'Änderungen veröffentlichen' : 'Publish your changes now'}</Button>
                    <hr />
                    This CMS-System was created by Bitwald<br />
                    Copyright © Bitwald UG (haftungsbeschränkt) {new Date(Date.now()).getFullYear()} <br /><br />
                    <strong>Contact</strong> <br />
                    Email: os@bitwald.com <br />
                  </div>
                </nav>
              </div>
              <div id="content">
                <Container>
                  <Row lg={12}>
                    <Col>
                      <h2>{this.state.language === 'de' ? 'Inhalte deiner Website' : 'Your Website Content'}</h2>
                    </Col>
                  </Row>
                  <strong><p>{this.state.language === 'de' ? 'Du bearbeitest alle deutschen Einträge.' : 'You´re editing all english entries.'}</p></strong>
                </Container>
                <Container>
                  <h3 className='item-type'>Seite: {this.getTypeName()}</h3>
                  <Container>
                    {
                      this.state.pages && this.state.selectedPageId && this.state.pages.filter((page) => page.id === this.state.selectedPageId)[0].name === 'Files' && (
                        <div className='mb-5'>
                          <p>Hier kannst du Dateien hochladen. Diese erscheinen nach dem Upload an letzter Stelle der Liste.</p>
                          <input className='input' name='new-file' type='file' onChange={this.onChangeImg} />
                          <button className='btn btn-primary' disabled={!this.state.selectedImage}  onClick={() => this.publishImage()}>Datei hochladen</button>
                        </div>
                      )
                    }
                    <Row>
                      {(this.state.selectedPageId) &&
                        this.getTypeArray().map((item) => {
                          return (
                            item.type === 'text' ? (
                              <div key={item.id}>
                                <p className='item-name'>Abschnitt: {item.name}</p>
                                {
                                  (this.state.selectedTypeUid === item.uid) ? (
                                    <div>
                                      <RichTextEditor
                                        key={item.id}
                                        id={item.id}
                                        placeholder={item.placeholder}
                                        value={this.state.editorState}
                                        onChange={this.onChange}
                                      />
                                      <br />
                                      <Button variant="light" onClick={() => this.setState({ selectedTypeUid: null })}>Close</Button>
                                    </div>

                                  ) : <a onClick={this.onSelectFormItem} name={item.uid}><div className="light" id={item.id} name={item.uid} dangerouslySetInnerHTML={{ __html: ((this.state[item.uid] !== 'null') && (this.state[item.uid] !== null)) ? this.state[item.uid].replace('null', '') : '' }} />
                                      {(this.state[item.uid] === 'null' || this.state[item.uid] === null || !this.state[item.uid]) ? 'This field is empty' : ''}
                                    </a>
                                }

                              </div>
                            ) : (
                                <div className='col-md-4 mb-5 mr-5 file-box' key={item.id}>
                                  <p className='item-type'>
                                    Name:&nbsp;{item.name} <br />
                                  </p>
                                  <p className='item-link'>
                                    Link:&nbsp;<a target='_blank' href={BASE_URL + 'static/' + item.content}>{BASE_URL + 'static/' + item.content}</a>
                                  </p>
                                  {item.content && <img src={BASE_URL + 'static/' + (this.state.uploadedImageId || item.content)} width='200px' />} <br /><br />
                                </div>
                              )
                          )
                        })
                      }
                    </Row>
                  </Container>
                </Container>
              </div>
            </div>
          </div>
        ) : (
            <Container className='login'>
              <Row>
                <Col lg={8}>
                  <h3>Bitwald CMS - Sign in</h3>
                  <Form>
                    <Form.Group size="md" className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <FormControl aria-label="Small" type="email" name="email" onChange={this.onInputChange} aria-describedby="inputGroup-sizing-md" />
                    </Form.Group>
                    <Form.Group size="md" className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <FormControl aria-label="Small" type="password" name="password" onChange={this.onInputChange} aria-describedby="inputGroup-sizing-md" />
                    </Form.Group>
                    <Button variant="primary" onClick={this.onLogin}>Login</Button>
                  </Form>
                </Col>
              </Row>
            </Container>
          )
        }
      </div>
    )
  }
}

export default App;
