import Hashids from 'hashids'
const salt = 'adj49dmv9!c'
const alphabet = 'abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ123456789'
const minHashLength = 8

export function encode(num) {
    const hash = new Hashids(salt, minHashLength, alphabet)
    return hash.encode([0, 7, num, 1, 3])
}

export function decode(str) {
    const hash = new Hashids(salt, minHashLength, alphabet)

    return Number(hash.decode(str)[2])
}