import * as Noty from 'noty'

export function notifyUser(_title, message, type) {

    switch (type) {
        case 'success':
            new Noty({
                layout: 'topCenter',
                progressBar: true,
                text: message,
                theme: 'mint',
                timeout: 2400,
                type: 'success'
            }).show()
            break
        case 'warning':
            new Noty({
                layout: 'topCenter',
                progressBar: true,
                text: message,
                theme: 'mint',
                timeout: 2400,
                type: 'error'
            }).show()
            break
        case 'info':
            new Noty({
                layout: 'topCenter',
                progressBar: true,
                text: message,
                theme: 'mint',
                timeout: 2400,
                type: 'info'
            }).show()
            break
        default:
            new Noty({
                layout: 'topCenter',
                progressBar: true,
                text: message,
                theme: 'mint',
                timeout: 2400,
                type: 'info'
            }).show()
            break
    }
}