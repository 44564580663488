import { initSubmit } from "./service";
import { notifyUser } from "./notification"
import crypto from 'crypto'

export async function valPassword(password, dbPassword, salt, email, userId) {
    const credentials = salten(password, salt)
    const valid = compareHashes(dbPassword, credentials.passwordHash)

    if (valid === true) {
        const res = await initSubmit('auth', { uid: email, id: userId })

        if (res.data) {
            localStorage.setItem('x-access-token', res.data)
            return true
        }

        return false
    } else {
        notifyUser('-', 'Leider stimmen deine Daten nicht mit unseren überein.', 'warning')
        return false
    }
}

function salten(password, salt) {
    const hash = crypto.createHmac('sha512', salt) /** Hashing algorithm sha512 */
    hash.update(password)
    const value = hash.digest('hex')
    return {
        passwordHash: value,
        salt
    }
}

function compareHashes(dbPW, loginPW) {
    if (dbPW === loginPW) {
        return true
    } else {
        return false
    }
}