import axios from 'axios'
import { BASE_URL } from './base-url';

export async function checkToken() {
    const url = BASE_URL + 'auth'
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    config.headers['x-access-token'] = localStorage.getItem('x-access-token')

    const res = await axios.get(url, config)

    return res.data
}

export async function initFetch(entity, param, isLoginRequest) {
    const url = BASE_URL + entity + (param ? '/' + param : '')
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    if (localStorage.getItem('x-access-token')) {
        config.headers['x-access-token'] = localStorage.getItem('x-access-token')
        config.headers['cms-request'] = 'true'
    }

    if (isLoginRequest) {
        config.headers.type = 'login/try'
    }

    let res = null

    try {
        res = await axios.get(url, config)
    } catch (error) {
        /** Logout user */
        console.log(error)
    }

    return res
}

export async function initSubmit(entity, payload) {
    const url = BASE_URL + entity
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('x-access-token')
        }
    }

    const res = await axios.post(url, payload, config)

    return res
}

export async function initPatch(entity, payload) {
    const url = BASE_URL + entity
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('x-access-token')
        }
    }

    const res = await axios.patch(url, payload, config)

    return res
}